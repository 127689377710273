import React, { Component } from "react";
import Layout from "../components/layout/layout";
import "../css/helpdesk-contact-us.css";

const ContactUsHelpdesk = () => {
  return (
    <Layout>
      <div class="helpdesk-help-container">
        <span className="help-header">Contact Us</span>
        <span className="help-description">
          Couldn't find what you were looking for?
        </span>
        <span className="help-description">
          Questions, bug requests, feedback - we're here for all of it.
        </span>
        <div className="help-bottom-divider"/>
        <span className="help-description">
          If you're looking for support around <strong>"Employee Helpdesk"</strong> - {" "}
          <span>
            Write to us at{" "}
            <a href="mailto:support@infeedo.com">support@infeedo.com</a>
          </span>
        </span>
      </div>
    </Layout>
  );
};

export default ContactUsHelpdesk;
